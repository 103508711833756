import React from "react";
import { motion } from "framer-motion";

const Hero: React.FC = () => {
  const FEATURE_ICONS = [
    "https://createsiteai.b-cdn.net/c/_/2d73a98b-8d1a-41a8-9aad-f22c7b438667",
    "https://createsiteai.b-cdn.net/c/_/e52b6643-0de5-45a0-bdc6-979772823919",
    "https://createsiteai.b-cdn.net/c/_/7d967705-095d-45d0-99c9-33f4b7a396f7",
  ];

  const ARROW_ICON =
    "https://createsiteai.b-cdn.net/c/_/50399a03-1a76-487a-a142-b9a850fc62f1";

  return (
    <div className="relative min-h-screen bg-[#030712] text-white overflow-hidden">
      <style>{`
        .technical-grid {
          background-image: 
            linear-gradient(rgba(29, 78, 216, 0.05) 1px, transparent 1px),
            linear-gradient(90deg, rgba(29, 78, 216, 0.05) 1px, transparent 1px);
          background-size: 20px 20px;
        }
        .schematic-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: 
            linear-gradient(45deg, rgba(59, 130, 246, 0.03) 1px, transparent 1px),
            linear-gradient(-45deg, rgba(124, 58, 237, 0.03) 1px, transparent 1px);
          background-size: 40px 40px;
          opacity: 0.3;
          pointer-events: none;
        }
        .data-line {
          position: absolute;
          background: linear-gradient(to right, transparent, rgba(59, 130, 246, 0.2), transparent);
          height: 1px;
          width: 100%;
          opacity: 0.5;
        }
        .technical-border {
          position: relative;
          overflow: hidden;
        }
        .technical-border::before {
          content: '';
          position: absolute;
          top: -50%;
          left: -50%;
          width: 200%;
          height: 200%;
          background: repeating-linear-gradient(
            0deg,
            transparent,
            transparent 10px,
            rgba(59, 130, 246, 0.05) 10px,
            rgba(59, 130, 246, 0.05) 20px
          );
          animation: rotate 20s linear infinite;
          opacity: 0.3;
        }
        @keyframes rotate {
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>

      {/* Technical Background Layers */}
      <div className="technical-grid absolute inset-0 z-0"></div>
      <div className="schematic-overlay absolute inset-0 z-1"></div>

      <div className="relative z-10 container mx-auto px-4 pt-24">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center relative"
        >
          {/* Mission Control Indicator */}
          <div
            className="
            absolute 
            -top-16 
            left-1/2 
            -translate-x-1/2 
            flex 
            items-center 
            gap-3 
            bg-blue-900/20 
            border 
            border-blue-500/30 
            px-5 
            py-2 
            rounded-full 
            text-xs 
            tracking-widest 
            uppercase 
            technical-border
          "
          >
            <div className="w-3 h-3 bg-blue-500 rounded-full animate-pulse"></div>
            Interplanetary Mission Control
          </div>

          {/* Main Headline */}
          <h1
            className="
            text-[6rem] 
            mobile:text-5xl 
            tablet:text-7xl 
            laptop:text-[6rem] 
            font-extralight 
            mb-6 
            tracking-tighter 
            bg-clip-text 
            text-transparent 
            bg-gradient-to-r 
            from-blue-100 
            to-purple-300
            relative
          "
          >
            Martian
            <br />
            Rocket Systems
            <div className="data-line absolute left-0 right-0 top-1/2 opacity-20"></div>
            <div className="data-line absolute left-0 right-0 bottom-1/2 opacity-20"></div>
          </h1>

          {/* Subheadline */}
          <p
            className="
            max-w-4xl 
            mx-auto 
            text-2xl 
            mobile:text-lg 
            tablet:text-xl 
            laptop:text-2xl 
            text-gray-400 
            mb-16 
            font-light 
            leading-relaxed
          "
          >
            Pioneering advanced rocket engineering and mission design for
            sustainable Mars exploration through cutting-edge technological
            innovation and precision-driven research.
          </p>
        </motion.div>

        {/* Feature Grid */}
        <div className="grid grid-cols-1 tablet:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {[
            {
              icon: (
                <img
                  src={FEATURE_ICONS[0]}
                  className="w-20 h-20 text-blue-400"
                />
              ),
              title: "Advanced Propulsion",
              description:
                "Quantum-enhanced plasma propulsion with unprecedented efficiency and range",
              stats: "Efficiency: 99.2%",
              gradient: "from-blue-900/40 to-blue-900/10",
              borderColor: "border-blue-500/30",
              statsColor: "text-blue-300",
            },
            {
              icon: (
                <img
                  src={FEATURE_ICONS[1]}
                  className="w-20 h-20 text-green-400"
                />
              ),
              title: "Computational Modeling",
              description:
                "Hyper-advanced AI-driven mission simulation using quantum computing architectures",
              stats: "Precision: ±0.0001°",
              gradient: "from-green-900/40 to-green-900/10",
              borderColor: "border-green-500/30",
              statsColor: "text-green-300",
            },
            {
              icon: (
                <img
                  src={FEATURE_ICONS[2]}
                  className="w-20 h-20 text-red-400"
                />
              ),
              title: "Mars Colonization",
              description:
                "Comprehensive multi-phase infrastructure strategy for sustainable extraterrestrial settlement",
              stats: "Mission Readiness: 82%",
              gradient: "from-red-900/40 to-red-900/10",
              borderColor: "border-red-500/30",
              statsColor: "text-red-300",
            },
          ].map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: index * 0.2, duration: 0.5 }}
              className={`
                bg-gradient-to-br 
                ${feature.gradient} 
                ${feature.borderColor}
                border 
                p-8 
                rounded-3xl 
                backdrop-blur-sm 
                hover:border-white/40 
                transition-all 
                duration-300 
                transform 
                hover:-translate-y-4 
                hover:shadow-2xl 
                hover:shadow-blue-500/20
                relative
                overflow-hidden
                technical-border
              `}
            >
              {/* Feature Content */}
              <div className="flex justify-center mb-6 relative z-10">
                {feature.icon}
              </div>

              <h3
                className="
                text-2xl 
                font-semibold 
                mb-4 
                text-white 
                relative 
                z-10
              "
              >
                {feature.title}
              </h3>

              <p
                className="
                text-gray-300 
                text-base 
                mb-6 
                relative 
                z-10 
                min-h-[4rem]
              "
              >
                {feature.description}
              </p>

              <div
                className="
                bg-white/10 
                rounded-xl 
                p-4 
                text-center 
                relative 
                z-10
              "
              >
                <span className={`text-sm ${feature.statsColor}`}>
                  {feature.stats}
                </span>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Call to Action */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8, duration: 0.5 }}
          className="text-center mt-20"
        >
          <button
            className="
            bg-gradient-to-r 
            from-blue-700 
            to-purple-800 
            hover:from-blue-800 
            hover:to-purple-900 
            text-white 
            font-medium 
            py-5 
            px-16 
            rounded-full 
            transition-all 
            transform 
            hover:scale-105 
            shadow-2xl 
            shadow-blue-500/30 
            hover:shadow-blue-500/50
            relative
            overflow-hidden
            group
            flex
            items-center
            justify-center
            gap-4
          "
          >
            <span className="relative z-10">Explore Mission Roadmap</span>
            <img
              src={ARROW_ICON}
              className="w-6 h-6 group-hover:translate-x-1 transition-transform"
            />
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
